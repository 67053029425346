<template>
  <div class="question-card w-100 py-4">
    <b-row no-gutters>
      <b-col cols="12" class="question-card__left-col">
        <!-- First section with 70% width -->
        <img
          src="@/assets/img/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />
        <!-- <p class="question-card__progress mb-0">
            Vraag {{ question }} van {{ max }}
          </p> -->
        <p class="question-card__question">
          Helaas voldoe je niet aan de eisen om een ViaTim punt te worden.
        </p>

        <div class="row no-gutters d-flex justify-content-end">
          <div class="col-lg-10 col-12 text-end">
            <button class="pt-4" @click="handleClick('analyze')">
              Ik woon wel op de begane grond
              <span class="mx-3">
                <img src="../../assets/img/forwardArrow.png" alt="" />
              </span>
            </button>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ThirdBQuestion",
  data() {
    return {
      selected: "",
    };
  },
  components: {},
  props: {
    question: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(val) {
      this.$emit("nextQuestion", val, "3985");
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "3");
    },
  },
  // watch: {
  //   selected() {
  //     if (this.selected == "3866" || this.selected == "3872") {
  //       this.$emit("nextQuestion", "2C");
  //     } else {
  //       this.$emit("nextQuestion", "3", this.selected);
  //     }
  //   },
  // },
};
</script>

<style lang="scss" scoped>
.question-card {
  .check-item {
    width: 72% !important;
  }
  button {
    border: 0;
    background: #ffffff;
    text-align: start;
    font-size: 22px;
    letter-spacing: 2px;
    line-height: 33px;
    color: #1d1729;
    font-weight: 600;
    font-family: "Source Sans 3", sans-serif;
  }
}
</style>
