<template>
  <div class="bullets_main_div">
    <h1 class="bullets_heading">WAT DOE JE ALS VIATIM PUNT?</h1>
    <div
      v-for="(bullet, index) in bullets"
      :key="index"
      class="bullet d-flex align-items-start"
    >
      <img src="../assets/img/check.png" alt="" class="bullet_img" />
      <h5 class="mb-0 bullet_point">
        {{ bullet.text }}
      </h5>
    </div>

    <!-- <div class="bullet d-flex align-items-start">
      <img src="../assets/img/check.png" alt="" class="bullet_img" />
      <h5 class="mb-0 bullet_point">
        Wij bezorgen pakketten voor jouw buurt bij jou thuis;
      </h5>
    </div>
    <div class="bullet d-flex align-items-start">
      <img src="../assets/img/check.png" alt="" class="bullet_img" />
      <h5 class="mb-0 bullet_point">
        Jij scant de pakketten (zo laat je weten de pakketten <br />ontvangen te
        hebben);
      </h5>
    </div>
    <div class="bullet d-flex align-items-start">
      <img src="../assets/img/check.png" alt="" class="bullet_img" />
      <h5 class="mb-0 bullet_point">
        Je buurtgenoten komen het pakket tussen <br />
        16:00 en 21:00 uur bij jou ophalen.
      </h5>
    </div>
    <div class="bullet d-flex align-items-start">
      <img src="../assets/img/check.png" alt="" class="bullet_img" />
      <h5 class="mb-0 bullet_point">
        Jouw buurtgenoten kunnen hun pakketten ook <br />
        inleveren bij jou. Wij halen deze pakketjes bij jou op.
      </h5>
    </div> -->
  </div>
</template>
<script>
export default {
  name: "Bullets",
  data() {
    return {
      bullets: [
        {
          text: "Wij bezorgen pakketten voor jouw buurt bij jou thuis;",
        },
        {
          text: "Jij scant de pakketten (zo laat je weten de pakketten ontvangen te hebben);",
        },
        {
          text: "Je buurtgenoten komen het pakket tussen 16:00 en 21:00 uur bij jou ophalen.",
        },
        {
          text: "Jouw buurtgenoten kunnen hun pakketten ook inleveren bij jou. Wij halen deze pakketjes bij jou op.",
        },
        {
          text: "Jij ontvangt per pakketje een vergoeding.",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.bullets_main_div {
  padding: 10rem 0px;
  @media (max-width: 992px) {
    padding: 2rem 0px;
  }
}
.bullet {
  margin-bottom: 10px;
}
.bullets_heading {
  font-size: 33px;
  color: #1d1729;
  font-weight: 800;
  font-family: "Source Sans 3", sans-serif;
  @media (max-width: 575.99px) {
    font-size: 25px;
  }
}
.bullet_img {
  margin-right: 12px;
  margin-top: 3px;
}
.bullet_point {
  font-size: 20px;
  color: #000000;
  /* font-weight: 600; */
  font-family: "Source Sans 3", sans-serif;
  @media (max-width: 575.99px) {
    font-size: 16px;
  }
}
</style>
