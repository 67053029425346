<template>
  <div
    class="d-flex w-100 h-100 justify-content-center align-items-center map-section position-relative"
  >
    <GmapMap
      :options="{
        zoomControl: false,
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: true,
        rotateControl: true,
        disableDefaultUi: false,
      }"
      :center="{
        lat: location.lat,
        lng: location.lng,
      }"
      :zoom="18"
      map-type-id="hybrid"
      style="width: 100%; height: 100%; border-radius: 100px"
    >
      <GmapMarker
        :position="{
          lat: location.lat,
          lng: location.lng,
        }"
        :clickable="true"
        :draggable="true"
      />
    </GmapMap>

    <div
      class="d-flex justify-content-center align-items-center question-card__right-col-text position-absolute"
    >
      <span>{{ address }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoogleMapSection",
  data() {
    return {
      center: { lat: 37.7749, lng: -122.4194 },
      zoom: 12,
    };
  },
  components: {},
};
</script>

<style lang="scss" scoped>
.map-section {
  //   border: 5px solid red !important;
  border-radius: 0px 30px 30px 0px !important;
  overflow: hidden !important;
}
</style>
