import LeadService from "@/services/LeadService.js";
import router from "@/router";
import axios from "axios";

const state = {
  leadLoading: false,
  leadResponse: {},
  getErrorResponse: {},
  // address: {},
  addressDetails: {},
  allStreets: [],
  address: "Belgium",
  location: {
    lat: 0,
    lng: 0,
  },
};

const getters = {
  leadLoading: (state) => state.leadLoading,
  leadResponse: (state) => state.leadResponse,
  address: (state) => state.address,
  location: (state) => state.location,
  addressDetails: (state) => state.addressDetails,
  allStreets: (state) => state.allStreets,
  getErrorResponses: (state) => state.getErrorResponse,
};

const actions = {
  async createLead({ commit, state }, payload) {
    // if (
    //   payload?.interested != "" &&
    //   payload.interested == "Ik zoek enkel informatie"
    // ) {
    //   router.push("/bedankt");
    // } else {
    commit("setLeadLoading", true);

    let obj = payload.data;
    if (state.addressDetails?.settlement) {
      obj = { ...obj, city: state.addressDetails?.settlement };
    }
    try {
      if (payload.bootCheck == true) {
        commit("setLeadLoading", false);

      } else {
        let { data } = await LeadService.createLead(obj);
        commit("setLeadResponse", data);
        commit("setLeadLoading", false);
        router.push("/bedankt");
      }

    } catch (error) {
      // let { data } = error?.response;
      commit("setLeadLoading", false);
      // if (data?.error == "Conflict - duplicate") {
      //   router.push("/bedankt");
      // } else {
      //   alert(data.error);
      // }
      if (error.response && error.response.data.error === "Conflict - duplicate") {
        console.log("error=>", error.response.data.error)
        router.push("/bedankt");

      } else {
        const errorKey = error.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
        commit("setError", { [errorKey]: false });
        console.error("Error Detail:", errorKey);
      }
    }
    // }
  },

  async getAddress({ commit, dispatch }, payload) {
    commit("setLeadLoading", true);

    const apiKey = "P6JTU52clKYjZca8";

    try {
      // const apiUrl = `https://api.pro6pp.nl/v2/autocomplete/be?authKey=${apiKey}&postalCode=${payload.zip}&street=${payload.street}`;
      const apiUrl = `https://api.pro6pp.nl/v2/suggest/be/street?authKey=${apiKey}&postalCode=${payload.zip}&street=${payload.street}`;

      const { data } = await axios.get(apiUrl);

      if (data.length > 0) {
        let {
          street,
          streetNumber,
          municipality,
          postalCode,
          country,
          lat,
          lng,
          region,
        } = data[0];
        let address =
          street +
          "," +
          " " +
          postalCode +
          " " +
          municipality +
          "," +
          " " +
          region +
          "," +
          " " +
          "Belgium";

        commit("setAddress", address);
        commit("setLocation", {
          lat: lat,
          lng: lng,
        });

        commit("setAddressDetails", data[0]);
        payload.onSuccess();
      } else {
        commit("setAddress", "Belgium");
        commit("setLocation", {
          lat: 50.5039,
          lng: 4.4699,
        });
        commit("setLeadLoading", false);
        commit("setAddressDetails", {});
        payload.onError();
      }

      commit("setLeadLoading", false);
    } catch (error) {
      payload.onError();

      commit("setAddress", "Belgium");
      commit("setLocation", {
        lat: 50.5039,
        lng: 4.4699,
      });
      commit("setLeadLoading", false);
      commit("setAddressDetails", {});
    }
  },

  async getStreets({ commit, dispatch }, payload) {
    commit("setLeadLoading", true);

    const apiKey = "P6JTU52clKYjZca8";

    try {
      const apiUrl = `https://api.pro6pp.nl/v2/suggest/be/street?authKey=${apiKey}&postalCode=${payload.zip}&street=${payload.street}`;
      const { data } = await axios.get(apiUrl);

      commit("setAllStreets", data);

      commit("setLeadLoading", false);
    } catch (error) {
      // console.log("Error", error);
      commit("setAddress", "Belgium");
      commit("setLocation", {
        lat: 50.5039,
        lng: 4.4699,
      });
      commit("setLeadLoading", false);
      commit("setAllStreets", []);
    }
  },

  async getStreetsByZip({ commit, dispatch }, payload) {
    commit("setLeadLoading", true);

    const apiKey = "P6JTU52clKYjZca8";

    try {
      const apiUrl = `https://api.pro6pp.nl/v2/suggest/be/street?authKey=${apiKey}&postalCode=${payload.zip}`;
      // const apiUrl = `https://api.pro6pp.nl/v2/suggest/be/street?authKey=${apiKey}&street=${payload.street}`;
      const { data } = await axios.get(apiUrl);

      commit("setAllStreets", data);

      commit("setLeadLoading", false);
    } catch (error) {
      // console.log("Error", error);
      commit("setAddress", "Belgium");
      commit("setLocation", {
        lat: 50.5039,
        lng: 4.4699,
      });
      commit("setLeadLoading", false);
      commit("setAllStreets", []);
    }
  },
};

const mutations = {
  setLeadLoading(state, payload) {
    state.leadLoading = payload;
  },
  setLeadResponse(state, payload) {
    state.leadResponse = payload;
  },
  setAddress(state, payload) {
    state.address = payload;
  },
  setLocation(state, payload) {
    state.location = payload;
  },
  setAddressDetails(state, payload) {
    state.addressDetails = payload;
  },
  setAllStreets(state, payload) {
    state.allStreets = payload;
  },
  setError(state, payload) {
    state.getErrorResponse = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
