<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="7" class="question-card__left-col">
        <!-- First section with 70% width -->
        <img
          src="@/assets/img/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">
          Ben je van maandag t/m vrijdag tussen 16:00 en 21:00 uur
          <span style="font-style: italic">thuis</span> en heb je dan geen
          verplichtingen?
        </p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
                class="align-items-center"
              >
                <div class="check-item" @click="handleClick('3991')">
                  <input
                    type="radio"
                    id="RadioButton1"
                    name="Button"
                    value="3991"
                  />
                  <label for="RadioButton" class="question-label"
                    >Ja, ik ben dan thuis.</label
                  >
                </div>

                <div class="check-item mt-3" @click="handleClick('3994')">
                  <input
                    type="radio"
                    id="RadioButton2"
                    name="Button"
                    value="3994"
                  />
                  <label for="RadioButton" class="question-label"
                    >Nee, ik ben dan niet thuis.</label
                  >
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <b-col
        cols="5"
        class="p-0 question-card__right-col px-3 px-md-5 d-flex justify-content-center align-items-center"
      >
        <img
          src="../../assets/img/secondQuestion.png"
          class="img-fluid"
          alt=""
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "SecondQuestion",
  data() {
    return {
      selected: "",
    };
  },
  props: {
    question: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(val) {
      if (val == "3991") {
        this.$emit("nextQuestion", "3", val);
      } else {
        this.$emit("nextQuestion", "2B", val);
      }
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "1");
    },
  },
  watch: {
    selected() {
      if (this.selected == "3982") {
        this.$emit("nextQuestion", "3", this.selected);
      } else {
        this.$emit("nextQuestion", "2B", this.selected);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  // &__right-col {
  //   background-image: url(../../assets/img/secondQuestion.png);
  //   background-size: 80% 80% !important;
  //   background-repeat: no-repeat;
  //   background-position: center;
  // }
}
#RadioButton1 {
  appearance: none;
  padding: 10px;
  background-color: #00e268;
  border-radius: 50%;
}
#RadioButton2 {
  appearance: none;
  padding: 10px;
  background-color: #e3007a;
  border-radius: 50%;
}
label {
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 23px;
  color: #1d1729;
  padding-left: 11px;
  font-weight: 600;
  cursor: pointer !important;
}
</style>
