var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex w-100 h-100 justify-content-center align-items-center map-section position-relative"},[_c('GmapMap',{staticStyle:{"width":"100%","height":"100%","border-radius":"100px"},attrs:{"options":{
      zoomControl: false,
      fullscreenControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: true,
      rotateControl: true,
      disableDefaultUi: false,
    },"center":{
      lat: _vm.location.lat,
      lng: _vm.location.lng,
    },"zoom":18,"map-type-id":"hybrid"}},[_c('GmapMarker',{attrs:{"position":{
        lat: _vm.location.lat,
        lng: _vm.location.lng,
      },"clickable":true,"draggable":true}})],1),_c('div',{staticClass:"d-flex justify-content-center align-items-center question-card__right-col-text position-absolute"},[_c('span',[_vm._v(_vm._s(_vm.address))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }