<template>
  <div class="hero">
    <b-container>
      <div class="d-lg-block w-100 d-none">
        <b-row class="hero__main-row">
          <b-col
            lg="5"
            xl="5"
            sm="10"
            md="10"
            xs="12"
            class="hero__main-row__left"
          >
            <div class="stairs_man">
              <img src="../assets/img/Laag 643.png" alt="" class="stairs" />
              <img src="../assets/img/arrow.png" alt="" class="arrow" />
            </div>
          </b-col>
          <b-col
            lg="7"
            xl="7"
            sm="10"
            md="10"
            xs="12"
            class="hero__main-row__right"
          >
            <b-row class="d-flex justify-content-center align-items-center">
              <b-col cols="12">
                <h1 class="heading text-norwap">VANUIT HUIS BIJVERDIENEN?</h1>
              </b-col>
              <b-col class="col-7">
                <!-- <h1 class="heading">VANUIT HUIS BIJVERDIENEN?</h1> -->
                <h1 class="sub_heading text-nowrap">NEEM PAKKETJES AAN</h1>
                <h1 class="sub_heading text-nowrap">VOOR JE BUURTGENOTEN</h1>
                <h1 class="sub_heading text-nowrap">EN VERDIEN BIJ!</h1>
              </b-col>
              <b-col
                class="col-5 d-flex flex-column justify-content-center align-items-start position-relative"
              >
                <!-- <img src="../assets/img/meldje.png" alt="" class="w-100" /> -->
                <img src="../assets/img/ZALANDO.png" alt="" class="w-100" />
              </b-col>
            </b-row>
            <Cards @onCardButtonClick="handleNextOFClick" />
          </b-col>
        </b-row>
      </div>

      <div class="d-lg-none d-block w-100">
        <b-row class="hero__main-row">
          <b-col sm="12" xs="12" class="hero__main-row__right">
            <h1 class="heading text-center">VANUIT HUIS BIJVERDIENEN?</h1>
          </b-col>

          <b-col cols="7">
            <img src="../assets/img/Laag 643.png" alt="" class="w-100" />
          </b-col>

          <b-col cols="5" class="d-flex align-items-center">
            <!-- <img src="../assets/img/meldje.png" alt="" class="w-100" /> -->
            <img src="../assets/img/ZALANDO.png" alt="" class="w-100" />
          </b-col>

          <b-col
            xs="12"
            class="d-flex flex-column justify-content-center align-items-center hero__main-row__right mt-3"
          >
            <h1 class="sub_heading text-center">NEEM PAKKETJES AAN</h1>
            <h1 class="sub_heading text-center">VOOR JE BUURTGENOTEN</h1>
            <h1 class="sub_heading text-center">EN VERDIEN BIJ!</h1>
          </b-col>
          <b-col xs="12" sm="12">
            <Cards @onCardButtonClick="handleNextOFClick" />
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import Cards from "./Cards.vue";
export default {
  name: "Hero",
  components: {
    Cards,
  },
  methods: {
    handleNextOFClick() {
      this.$emit("nextPage");
    },
  },

  props: {},
};
</script>
<style scoped lang="scss">
.hero {
  background: #f652ab;
  // height: 77vh;
  // @media (max-width: 1440px) {
  //   height: 72vh;
  // }
  // @media (max-width: 1299px) {
  //   height: 96vh;
  // }
  @media (max-width: 992px) {
    height: fit-content;
  }
  &__main-row {
    padding-top: 3rem;
    padding-bottom: 1rem;
    @media (max-width: 992px) {
      justify-content: center;
    }
    &__left {
      display: flex;
      align-items: end;
      // flex-direction: column;
      // align-items: center;
      .bookings {
        position: relative;
        .booking_img {
          width: 75%;
          @media (max-width: 1024px) {
            width: 62%;
          }
        }
        .oval {
          position: absolute;
          top: 11%;
          left: -7%;
          width: 18%;
        }
        .oval_text {
          width: fit-content;
          position: absolute;
          top: 18%;
          left: -5%;
          font-size: 18px;
          text-transform: uppercase;
          color: #000000;
          font-weight: 800;
          font-family: "Source Sans 3", sans-serif;
          text-align: center;
          @media (max-width: 1024px) {
            top: 23%;
            left: -4%;
            font-size: 16px;
          }
        }
        .Rechthoek {
          width: 100%;
          position: absolute;
          left: 22%;
          top: 54%;
          @media (max-width: 1024px) {
            width: 78%;
          }
        }
        .Rechthoek_text {
          width: 100%;
          position: absolute;
          top: 70%;
          left: 24%;
          transform: rotate(-9deg);
          font-size: 20px;
          text-transform: uppercase;
          color: #000000;
          font-weight: 800;
          font-family: "Source Sans 3", sans-serif;
          @media (max-width: 1024px) {
            width: 82%;
            font-size: 16px;
          }
          @media (max-width: 446px) {
            width: 77%;
            font-size: 15px;
          }
        }
      }
      .stairs_man {
        display: flex;
        position: relative;
        @media (max-width: 1024px) {
          margin-top: 3rem;
        }
        // @media (max-width: 992px) {
        //   display: none;
        // }
        .stairs {
          width: 92%;
          // height: 29rem;
          height: 26rem;
          // width: 100%;
          // height: 23rem;
          // @media (max-width: 1024px) {
          //   height: 22rem;
          // }

          @media (min-width: 1199.99px) and (max-width: 1399.99px) {
            height: 25rem !important;
          }
          @media (min-width: 991.99px) and (max-width: 1199.99px) {
            height: 21rem !important;
          }
        }
        .arrow {
          position: absolute;
          top: 59%;
          left: 84%;
          // top: 47%;
          // left: 82%;
          // @media (max-width: 1024px) {
          //   top: 41%;
          //   left: 82%;
          //   width: 18%;
          // }
          @media (max-width: 992px) {
            display: none;
          }
        }
      }
    }
    &__right {
      // @media (max-width: 992px) {
      //   margin-top: 2rem;
      // }
      .heading {
        font-size: 44pxpx;
        line-height: 46px;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: 800;
        font-family: "Source Sans 3", sans-serif;
        @media (min-width: 1199.99px) and (max-width: 1399.99px) {
          font-size: 35px;
        }
        @media (max-width: 1024px) {
          // font-size: 35px;
          font-size: 38px;
        }
      }
      .sub_heading {
        font-size: 26px;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: 700;
        font-family: "Source Sans 3", sans-serif;

        @media (max-width: 1200px) {
          font-size: 28px;
        }
        @media (max-width: 1024px) {
          font-size: 26px;
        }
        // @media (max-width: 1200px) {
        //   font-size: 28px;
        // }
        // @media (max-width: 1024px) {
        //   font-size: 23px;
        // }
      }
      .coins_1 {
        width: 49%;
      }
      .coins_2 {
        position: absolute;
        top: 47%;
        left: 29%;
        width: 49%;
      }
    }
  }
}
</style>