<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="12" class="question-card__left-col">
        <!-- First section with 70% width -->
        <img
          src="@/assets/img/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">
          Het is erg belangrijk dat je geen verplichtingen hebt tijdens de
          openingstijden.
        </p>
        <p class="question-card__question">
          Denk je die verplichtingen te kunnen combineren met het zijn van een
          ViaTim Punt?
        </p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <div class="check-item" @click="handleClick()">
                  <input
                    type="radio"
                    id="RadioButton1"
                    name="Button"
                    value=""
                  />
                  <label for="RadioButton" class="question-label"
                    >Ja, dat lukt.
                  </label>
                </div>
                <div class="check-item mt-3" @click="handleClick('nee')">
                  <input
                    type="radio"
                    id="RadioButton2"
                    name="Button"
                    value="nee"
                  />
                  <label for="RadioButton" class="question-label"
                    >Nee, dat lukt niet.
                  </label>
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "SecondBQuestion",
  data() {
    return {
      selected: "",
    };
  },
  components: {},
  props: {
    question: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(val) {
      if (val == "nee") {
        this.$emit("nextQuestion", "2C");
      } else {
        this.$emit("nextQuestion", "3");
      }
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "2");
    },
  },
  watch: {
    selected() {
      if (this.selected == "2bno") {
        this.$emit("nextQuestion", "2C");
      } else {
        this.$emit("nextQuestion", "3", this.selected);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  .check-item {
    width: 72% !important;
  }
  #RadioButton1 {
    appearance: none;
    padding: 10px;
    background-color: #00e268;
    border-radius: 50%;
  }
  #RadioButton2 {
    appearance: none;
    padding: 10px;
    background-color: #e3007a;
    border-radius: 50%;
  }
  label {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 23px;
    color: #1d1729;
    padding-left: 11px;
    font-weight: 600;
    cursor: pointer !important;
  }
}
</style>
