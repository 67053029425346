<template>
  <div>
    <Header @pageClick="previousPage" />
    <div class="background">
      <b-container>
        <b-row class="justify-content-center">
          <b-col xl="9" cols="12">
            <div class="thankyou-card">
              <h4 class="text-center">
                Bedankt voor je aanmelding! ViaTim neemt zo snel mogelijk
                contact met je op.
              </h4>
            </div>
          </b-col>
        </b-row>
      </b-container>

      <img
        referrerpolicy="no-referrer-when-downgrade"
        v-if="leadResponse?.profile?.id"
        :src="
          'https://republish.bijverdienexpert.be/m/5851/e1f53a9964a6/?event=6823&unique_conversion_id=' +
          leadResponse?.profile?.id
        "
      />
    </div>
  </div>
</template>

<script>
import Header from "../components/Header.vue";
export default {
  name: "ThankYou",
  components: { Header },
  methods: {
    previousPage() {
      this.$router.push("/");
    },
  },

  mounted() {
    let useScript = document.createElement("script");

    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };

        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";

        n.queue = [];
        t = b.createElement(e);
        t.async = !0;

        t.src = v;
        s = b.getElementsByTagName(e)[0];

        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",

        "https://connect.facebook.net/en_US/fbevents.js"
      ),

      fbq("init", "382090309408938"),

      fbq("track", "PageView"),
      fbq("track", "Lead")
    );
    document.head.appendChild(useScript);
  },

  created() {
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=382090309408938&ev=PageView&noscript=1"/></noscript>';
  },
};
</script>

<style lang="scss" scoped>
.background {
  background: #e3007a;
  padding: 5rem 0;
}
.thankyou-card {
  // margin-top: 50px;
  border-radius: 30px;
  filter: drop-shadow(0 0 13.5px rgba(0, 0, 0, 0.21));
  background-color: #ffffff;
  padding: 50px;
  color: #1d1729;

  @media (max-width: 767.99px) {
    padding: 30px !important;
    h2 {
      font-size: 25px !important;
    }
  }
  @media (max-width: 575.99px) {
    padding: 20px !important;
    h2 {
      font-size: 15px !important;
    }
  }
}

img {
  width: 1px;
  height: 1px;
  border: 0px;
}
</style>
