<template>
  <div class="question-card w-100">
    <b-row class="form justify-content-end pt-3 pt-lg-5 px-2 px-lg-4">
      <b-col lg="3" class="d-lg-block d-none"></b-col>
      <b-col lg="6" order-lg="1" order="2">
        <div class="form_texts">
          <h2 class="form__heading">Start met bijverdienen!</h2>
          <p class="form__sub-heading">Rond je aanmelding nu af.</p>
          <p class="form__sub-heading">Na afronding maak je ook kans op</p>
          <p class="form__sub-heading">
            een zalando cadeaukaart <span class="text-pink">t.w.v. €50,-</span>
          </p>
          <p class="mb-3 mt-0 text-green">
            Al meer dan 750+ mensen ging je voor.
          </p>
        </div>
      </b-col>

      <b-col
        lg="3"
        order-lg="2"
        order="1"
        class="d-flex align-items-center justify-content-center justify-content-lg-start"
      >
        <!-- <img src="../assets/img/with_card.png" alt="" class="img-fluid" /> -->
        <div class="form__bookings d-flex justify-content-center">
          <img src="../assets/zalando2.png" class="form__img" alt="" />

          <!-- <img src="../assets/img/fiftycircle.png" alt="" class="oval" />
         
          <img src="../assets/img/bol.com1.png" alt="" class="booking_img" /> -->
        </div>
      </b-col>
      <!-- <b-col
        cols="12"
        class="d-flex justify-content-center align-items-center position-relative form__top-row"
      >
        <div class="form_texts">
          <h2 class="form__heading">Start met bijverdienen!</h2>
          <p class="form__sub-heading">Rond je aanmelding nu af.</p>
          <p class="form__sub-heading">Na afronding maak je ook kans op</p>
          <p class="form__sub-heading">
            een bol.com cadeaukaart <span class="text-pink">t.w.v. €50,-</span>
          </p>
          <p class="mb-3 mt-0 text-green">
            Al meer dan XXX mensen ging je voor.
          </p>
        </div>
        <div class="form__bookings">
          <img src="../assets/img/Ovaal 4.png" alt="" class="oval" />
          <h5 class="oval_text">€50,-</h5>
          <img src="../assets/img/bol.com1.png" alt="" class="booking_img" />
        </div>
      </b-col> -->
    </b-row>
    <b-row class="pb-4">
      <b-col cols="12">
        <b-form @submit="handleSubmit">
          <input type="checkbox" class="hidden-checkbox" v-model="bootCheck" />
          <b-row class="radio_form">
            <b-form-group v-slot="{ ariaDescribedby }" class="mt-3 mb-0">
              <b-form-radio-group
                id="radio-group-1"
                v-model="details.gender"
                :options="genderOptions"
                :aria-describedby="ariaDescribedby"
                name="radio-options"
                class="radio"
              >
              </b-form-radio-group>
              <b-form-invalid-feedback>
                Voer een geldige e-mailadres in
              </b-form-invalid-feedback>
            </b-form-group>
            <p v-if="genderNotSelected" class="invalid-text mb-0">
              Selecteer er ten minste één
            </p>
          </b-row>
          <b-row class="d-flex justify-content-center mt-4">
            <b-col cols="5">
              <b-form-input
                type="text"
                v-model="details.firstname"
                @input="handleFieldInput('firstname')"
                placeholder="Voornaam"
                :state="details.firstname ? firstNameValidation : null"
                required
              ></b-form-input>
              <b-form-invalid-feedback v-if="!firstNameValidation">
                <!-- Voer een geldige voornaam in -->
                voornaam niet correct
              </b-form-invalid-feedback></b-col
            >
            <b-col cols="5">
              <b-form-input
                type="text"
                v-model="details.lastname"
                @input="handleFieldInput('lastname')"
                placeholder="Achternaam"
                :state="details.lastname ? lastNameValidation : null"
                required
              ></b-form-input>
              <b-form-invalid-feedback v-if="!lastNameValidation">
                <!-- Voer een geldige achternaam in -->
                achternaam niet correct
              </b-form-invalid-feedback>
            </b-col>
          </b-row>
          <b-row class="d-flex justify-content-center my-4">
            <b-col cols="5">
              <b-form-input
                placeholder="Telefoonnummer"
                type="text"
                v-model="details.phone_number"
                @input="handleFieldInput('phone_number')"
                :state="details.phone_number ? phoneValidation : null"
                required
              ></b-form-input>
              <b-form-invalid-feedback v-if="!phoneValidation">
                <!-- Voer een geldige telefoonnummer in -->
                telefoonnummer niet correct
              </b-form-invalid-feedback></b-col
            >
            <b-col cols="5">
              <b-form-input
                type="text"
                v-model="details.email"
                @input="handleFieldInput('email')"
                placeholder="E-mail adres"
                :state="details.email ? emailValidation : null"
                required
              ></b-form-input>
              <b-form-invalid-feedback v-if="!emailValidation">
                <!-- Voer een geldige e-mailadres in -->
                e-mailadres niet correct
              </b-form-invalid-feedback></b-col
            >
          </b-row>
          <b-row class="justify-content-center">
            <b-col cols="10">
              <b-button class="w-100" type="submit" :disabled="leadLoading">
                <b-spinner variant="dark" v-if="leadLoading"></b-spinner>
                <span v-else>VERSTUREN</span>
              </b-button>
            </b-col>
          </b-row>
          <b-row class="justify-content-center">
            <b-col cols="10">
              <p class="text-center bottom__text mt-2">
                Door je aanmelding af te ronden ga je automatisch akkoord met de
                Algemene Voorwaarden.
              </p>
            </b-col>
          </b-row>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Form",
  props: {
    details: {
      type: Object,
      required: true,
    },
    answers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      bootCheck: false,
      nameRegex: /^([a-zA-Z ]){1,30}$/,
      streetRegex: /^[a-zA-Z ]*$/,
      phoneRegex: /^0\d{9}$/,
      emailRegex: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
      zipRegex: /^([0-9]{4}[a-zA-Z]{2})$/,
      genderNotSelected: false,

      genderOptions: [
        { text: "Mevrouw", value: "female" },
        { text: "Meneer", value: "male" },
      ],
    };
  },

  methods: {
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    handleSubmit(e) {
      e.preventDefault();

      if (this.isValidForm && this.details.gender != "") {
        this.createLead({
          data: {
            ...this.details,
            bootCheck: this.bootCheck,
            answers: [...this.answers],
          },
        });
      } else {
        if (this.details.gender == "") {
          this.genderNotSelected = true;
          setTimeout(() => {
            this.genderNotSelected = false;
          }, 2500);
        }
      }
    },
  },

  computed: {
    ...mapGetters(["getErrorResponses"]),
    zipValidation() {
      return this.zipRegex.test(this.details.zip) || this.details.zip === "";
    },
    houseNumberValidation() {
      return (
        this.houseRegex.test(this.details.house_number) ||
        this.details.house_number === ""
      );
    },
    firstNameValidation() {
      if (this.details.firstname === "" && !this.getErrorResponses.firstname) {
        return null;
      }
      if (this.getErrorResponses.firstname == false) {
        return false;
      } else if (
        this.nameRegex.test(this.details.firstname) ||
        this.details.firstname === ""
      ) {
        return true;
      }
      return (
        this.nameRegex.test(this.details.firstname) ||
        this.details.firstname === ""
      );
    },
    lastNameValidation() {
      if (this.details.lastname === "" && !this.getErrorResponses.lastname) {
        return null;
      }
      if (this.getErrorResponses.lastname == false) {
        return false;
      } else if (
        this.nameRegex.test(this.details.lastname) ||
        this.details.lastname === ""
      ) {
        return true;
      }
      return (
        this.nameRegex.test(this.details.lastname) ||
        this.details.lastname === ""
      );
    },
    phoneValidation() {
      if (
        this.details.phone_number === "" &&
        !this.getErrorResponses.phone_number
      ) {
        return null;
      }
      if (this.getErrorResponses.phone_number == false) {
        return false;
      } else if (
        this.phoneRegex.test(this.details.phone_number) ||
        this.details.phone_number === ""
      ) {
        return true;
      }
      return (
        this.phoneRegex.test(this.details.phone_number) ||
        this.details.phone_number === ""
      );
    },
    emailValidation() {
      if (this.details.email === "" && !this.getErrorResponses.email) {
        return null;
      }
      if (this.getErrorResponses.email == false) {
        return false;
      } else if (
        this.emailRegex.test(this.details.email) ||
        this.details.email === ""
      ) {
        return true;
      }
      return (
        this.emailRegex.test(this.details.email) || this.details.email === ""
      );
    },
    isValidForm() {
      return (
        this.firstNameValidation &&
        this.lastNameValidation &&
        this.phoneValidation &&
        this.emailValidation
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.hidden-checkbox {
  opacity: 0;
}
.form-control {
  background-color: #ffffff !important;
  border: 1px solid #cccbcb !important;
  border-radius: 0px;
  font-size: 20px;
  padding-left: 11px;
  letter-spacing: 2px;
  line-height: 33px;
  color: #bbbabc;
  font-weight: 600;
  font-family: "Source Sans 3", sans-serif;
  @media (max-width: 775.99px) {
    font-size: 16px;
  }
}
.form__img {
  width: 100% !important;
  @media (max-width: 991.99px) {
    width: 50% !important;
  }
}

.bottom__text {
  font-size: 18px;
  color: #1d1729;
  font-family: "Source Sans 3", sans-serif;
  text-align: center;
  @media (max-width: 775.99px) {
    font-size: 14px;
  }
  @media (max-width: 575.99px) {
    font-size: 10px;
  }
}
.form {
  &__top-row {
    @media (max-width: 767.99px) {
      flex-direction: column-reverse;
    }
  }
  &__heading {
    // font-size: 47px;
    font-size: 42px;
    color: #1d1729;
    margin-bottom: 1px !important;
    font-weight: bold;
    font-family: "Source Sans 3", sans-serif;
    text-align: center;
    @media (max-width: 992px) {
      font-size: 36px;
    }
    @media (max-width: 775.99px) {
      font-size: 30px;
    }
  }
  &__sub-heading {
    // font-size: 24px;
    font-size: 22px;
    margin-bottom: 1px !important;
    color: #1d1729;
    font-weight: bold;
    font-family: "Source Sans 3", sans-serif;
    text-align: center;
    @media (max-width: 992px) {
      font-size: 19px;
    }
    @media (max-width: 775.99px) {
      font-size: 16px;
    }
  }
  &__bookings {
    position: relative;
    // left: 11%;
    // @media (max-width: 1299px) {
    //   left: 7%;
    //   top: 1rem;
    // }
    // @media (max-width: 992px) {
    //   left: 6%;
    //   top: 1rem;
    // }
    // @media (max-width: 767.99px) {
    //   margin-bottom: 2rem;
    // }
    .oval {
      position: absolute;
      // top: 1rem;
      top: 1.4rem;
      width: 23%;
      left: 9.3rem;
      @media (max-width: 992px) {
        left: 10.5rem;
      }
      @media (max-width: 767.99px) {
        left: 12.5rem;
      }
      @media (max-width: 575.99px) {
        left: 14.5rem;
        top: 2rem;
      }
    }
    .oval_text {
      width: fit-content;
      position: absolute;
      font-size: 14px;
      font-weight: 800;
      left: 9.7rem;
      top: 1.8rem;
      @media (max-width: 992px) {
        left: 10.7rem;
      }
      @media (max-width: 767.99px) {
        left: 12.7rem;
      }
      @media (max-width: 575.99px) {
        left: 14.7rem;
        top: 3rem;
      }
    }
  }
}
.text-green {
  font-size: 18px;
  color: #00e268;
  font-weight: 800;
  font-family: "Source Sans 3", sans-serif;
  text-align: center;
  @media (max-width: 775.99px) {
    font-size: 17px;
  }
}
.radio_form {
  padding: 0 6rem;
}
#radio-group-1 {
  display: flex;
}
</style>
