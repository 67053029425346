<template>
  <b-row class="gallery">
    <b-col
      class="d-flex jusitfy-content-center align-items-center position-relative"
    >
      <img src="../assets/img/Laag 640.png" alt="" class="gallery__img_1" />
      <img src="../assets/img/base.png" alt="" class="gallery__img_2" />
      <h1 class="msg_heading">750+</h1>
      <h5 class="msg_text">
        Anderen zijn al <br />
        pakketpunt
      </h5>
      <img src="../assets/img/Laag 644.png" alt="" class="gallery__img_3" />
    </b-col>
    <b-col class="position-relative"> </b-col>
  </b-row>
  <!-- <b-row>
    <b-col
      class="d-flex justify-content-start align-items-center position-relative"
    >
      <img src="../assets/img/Laag 640.png" alt="" class="delivery_img" />
      <img src="../assets/img/base.png" alt="" class="message_img" />
      <div class="postition-relative">
        <h1 class="msg_heading">XXX</h1>
        <h5 class="msg_text">Anderen zijn al palkketpunt</h5>
      </div>
    </b-col>
    <b-col class="position-relative">
      <img src="../assets/img/Laag 644.png" alt="" class="car_delivery" />
    </b-col>
  </b-row> -->
</template>
<script>
export default {
  name: "Gallery",
};
</script>
<style scoped lang="scss">
.gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__img {
    &_1 {
      width: 80%;
    }
    &_2 {
      width: 30%;
      position: absolute;
      left: 73%;

      @media (max-width: 767.99px) {
        width: 24%;
      }
    }
    &_3 {
      width: 47% !important;
      position: absolute;
      // top: 96%;
      top: 85%;
      left: 49%;
      @media (max-width: 767.99px) {
        width: 36%;
        display: none !important;
      }
      @media (max-width: 575px) {
        width: 36%;
      }
    }
  }
  .msg_heading {
    // font-size: 26px;
    font-size: 23px;
    line-height: 13px;
    color: #ffffff;
    font-weight: 900;
    position: absolute;
    // left: 78%;
    // top: 44%;
    left: 79.5%;
    top: 42.3%;

    @media (max-width: 767.99px) {
      font-size: 16px;
      line-height: 8px;
      left: 78%;
      top: 44%;
    }
    // @media (max-width: 391px) {
    //   font-size: 16px;
    //   line-height: 6px;
    // }

    // @media (max-width: 750px) {
    //   font-size: 21px;
    //   line-height: 8px;
    // }
    // @media (max-width: 391px) {
    //   font-size: 16px;
    //   line-height: 6px;
    // }
  }
  .msg_text {
    // font-size: 15px;
    font-size: 12px;
    line-height: 13px;
    color: #ffffff;
    font-weight: 500;
    position: absolute;
    // left: 78%;
    // top: 50%;
    left: 79.5%;
    top: 49%;

    @media (max-width: 767.99px) {
      font-size: 9px;
      line-height: 8px;
      left: 78%;
      top: 48.5%;
    }
    // @media (max-width: 750px) {
    //   font-size: 11px;
    //   line-height: 11px;
    // }
    // @media (max-width: 391px) {
    //   font-size: 9px;
    //   line-height: 9px;
    // }
  }
}
/* .message_img {
  position: absolute;
  width: 23%;
  top: 35%;
  left: 73%;
  @media (max-width: 570px) {
    display: none;
  }
}
.msg_heading {
  font-size: 23px;
  line-height: 11px;
  color: #ffffff;
  font-weight: 900;
  font-family: 'Source Sans 3', sans-serif;
  position: absolute;
  top: 9.5rem;
  left: 77%;
  @media (max-width: 992px) {
    top: 8.5rem;
    font-size: 16px;
  }
}
.msg_text {
  font-size: 15px;
  line-height: 14px;
  color: #ffffff;
  font-weight: 500;
  font-family: 'Source Sans 3', sans-serif;
  position: absolute;
  top: 10.7rem;
  left: 77%;
  @media (max-width: 992px) {
    font-size: 10px;
    line-height: 11px;
    top: 9.5rem;
  }
}
.car_delivery {
  position: absolute;
  width: 54%;
  left: 56%;
  top: -5rem;
  @media (max-width: 1485px) {
    left: 51%;
  }
  @media (max-width: 1399px) {
    top: -4rem;
    left: 60%;
  }
  @media (max-width: 1300px) {
    left: 53%;
    top: -2rem;
  }
  @media (max-width: 1200px) {
    left: 52%;
    top: -1rem;
    width: 48%;
  }
  @media (max-width: 570px) {
    width: 39%;
  }
}
.delivery_img {
  @media (max-width: 992px) {
    width: 80%;
  }
  @media (max-width: 570px) {
    width: 58%;
  }
} */
</style>
