<template>
  <div class="postcode bg-site-primary">
    <b-container>
      <b-form @submit="handleNextPage">
        <b-row>
          <b-col cols="12" md="8" class="d-flex align-items-start">
            <img
              src="../assets/img/arrow-right.png"
              class="img-fluid footer_image"
              alt="arrow"
            />

            <span class="ml-md-5 ml-5 postcode__text">
              Meld je aan en start met bijverdienen vanuit huis!
            </span>
          </b-col>
          <b-col cols="12" md="4" class="mt-md-0 mt-3">
            <div class="row no-gutters">
              <div class="col-8">
                <!-- <b-form-input
                  v-model="postdata.zip"
                  placeholder="Postcode"
                  :state="postdata.zip ? zipValidation : null"
                  required
                ></b-form-input>
                <b-form-invalid-feedback v-if="!zipValidation">
                  Voer een geldige postcode in
                </b-form-invalid-feedback> -->
                <b-form-input
                  class="postCode"
                  v-model="postdata.zip"
                  placeholder="Postcode"
                  :state="postdata.zip ? zipValidation : null"
                  required
                ></b-form-input>
                <b-form-invalid-feedback v-if="!zipValidation">
                  Voer een geldige postcode in
                </b-form-invalid-feedback>
              </div>
              <div class="col-4">
                <b-form-input
                  v-model="postdata.house_number"
                  placeholder="Huisnr."
                  class="ml-2"
                  required
                  :state="postdata.house_number ? houseNumberValidation : null"
                ></b-form-input>
                <b-form-invalid-feedback v-if="!houseNumberValidation">
                  Voer een geldige huisnr in
                </b-form-invalid-feedback>
              </div>
            </div>

            <b-button class="mt-3 w-100" type="submit">
              Aanmelden als pakketpunt
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      zipRegex: /^([0-9]{4})$/,
      houseRegex: /^[0-9]+$/,
    };
  },
  props: {
    postdata: {
      type: Object,
      required: true,
    },
  },

  methods: {
    handleNextPage(e) {
      e.preventDefault();
      if (this.isValidForm) {
        // this.getAddress({
        //   zip: this.postdata.zip,
        //   house_number: this.postdata.house_number,
        // });
        this.$emit("nextPage", "post");
        // this.$emit("nextPage", "post", {
        //   zip: this.postdata.zip,
        //   house_number: this.postdata.house_number,
        // });
      }
    },
  },
  computed: {
    zipValidation() {
      return this.zipRegex.test(this.postdata.zip) || this.postdata.zip === "";
    },
    houseNumberValidation() {
      return (
        this.houseRegex.test(this.postdata.house_number) ||
        this.postdata.house_number === ""
      );
    },
    isValidForm() {
      return this.zipValidation && this.houseNumberValidation;
    },
  },
};
</script>

<style lang="scss" scoped>
.postcode {
  padding-top: 70px;
  padding-bottom: 70px;

  &__text {
    font-size: 46px;
    line-height: 48px;
    color: #ffffff;
    font-weight: 800;
  }
  .form-control {
    text-transform: capitalize;
    letter-spacing: 2px;
    font-weight: 600;
    border-radius: 0 !important;
  }
  .footer_image {
    margin-right: 3rem;
  }
  .invalid-feedback {
    color: #ffffff;
    font-size: 11px;
  }
  .btn {
    border-radius: 37px;
    background-color: #00e268;
    padding: 10px;
    // font-size: 17px;
    font-size: 15px;
    letter-spacing: 2px;
    text-transform: uppercase;
    // color: #ffffff;
    color: black !important;
    font-weight: 900;
    border: none !important;

    &:hover,
    :active {
      background-color: #00e268;
      color: #ffffff;
    }
  }

  @media (max-width: 1023.99px) {
    &__text {
      font-size: 25px;
      line-height: 30px;
    }
    .btn {
      font-size: 11px;
    }
    img {
      width: 30px;
    }
  }

  @media (max-width: 767.99px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  @media (max-width: 575.99px) {
    &__text {
      font-size: 20px;
      line-height: 25px;
    }
    .btn {
      font-size: 12px;
      letter-spacing: 1px;
    }
    img {
      width: 25px;
    }
  }
}
</style>
