<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="7" class="question-card__left-col">
        <img
          src="@/assets/img/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        />
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">
          Wat zijn jouw postcode en huisnummer?
        </p>

        <b-form @submit="handleNextQuestion">
          <div class="row no-gutters first-question">
            <div class="col-lg-10 col-12">
              <div class="row no-gutters">
                <div class="col-7 pr-1">
                  <b-form-input
                    v-model="details.zip"
                    placeholder="Postcode"
                    :state="details.zip ? zipValidation : null"
                    required
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!zipValidation">
                    Voer een geldige postcode in
                  </b-form-invalid-feedback>
                </div>
                <div class="col-5 pl-1">
                  <b-form-input
                    v-model="details.house_number"
                    placeholder="Huisnr."
                    required
                    :state="details.house_number ? houseNumberValidation : null"
                  ></b-form-input>
                  <b-form-invalid-feedback v-if="!houseNumberValidation">
                    Voer een geldige huisnr in
                  </b-form-invalid-feedback>
                </div>
              </div>

              <div class="col-12">
                <div class="mt-3 auto-complete">
                  <!-- <multiselect
                    v-model="details.street"
                    :options="options"
                    placeholder="Straatnaam"
                  >
                  </multiselect> -->

                  <multiselect
                    v-model="selectedStreet"
                    :options="streetItems"
                    placeholder="Straatnaam."
                    :allow-empty="true"
                    selectLabel="Druk op enter om te selecteren"
                    deselectLabel="Druk op enter om te verwijderen"
                    @search-change="searchOnChange"
                  >
                    <template slot="noResult"
                      >Straatnaam niet gevonden</template
                    >
                    <template slot="noOptions">Straten niet gevonden</template>
                  </multiselect>
                  <p class="invalid-text mb-0" v-if="invalidStreet">
                    Vul alstublieft dit veld in
                  </p>

                  <p class="invalid-text mb-0" v-if="isInvalidAddress">
                    adres niet gevonden voor u opgegeven postcode en straatnaam
                  </p>
                </div>
              </div>

              <b-button class="mt-3 w-100" type="submit">
                VOLGENDE
                <span
                  ><img
                    src="../../assets/img/arrowRight-black.png"
                    alt=""
                    class="btn_img" /></span
              ></b-button>
            </div>
          </div>
        </b-form>
      </b-col>
      <b-col
        cols="5"
        class="p-0 question-card__right-col px-3 px-md-5 d-flex justify-content-center align-items-center"
      >
        <img
          src="../../assets/img/firstQuestion.png"
          class="img-fluid"
          alt=""
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  name: "FirstQuestion",
  data() {
    return {
      zipRegex: /^([0-9]{4})$/,
      houseRegex: /^[0-9]+$/,
      isInvalidAddress: false,
      // details: {
      //   zip: "",
      //   house_number: "",
      // },
      streetItems: [],
      selectedStreet: "",
      invalidStreet: false,
    };
  },
  components: { Multiselect },

  props: {
    details: {
      type: Object,
      required: true,
    },
    question: {
      type: String,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleNextQuestion(e) {
      e.preventDefault();

      if (this.details.street == "" || this.details.street == null) {
        this.invalidStreet = true;
        setTimeout(() => {
          this.invalidStreet = false;
        }, 3000);
      } else if (this.isValidForm) {
        this.getAddress({
          zip: this.details.zip,
          house_number: this.details.house_number,
          street: this.details.street,
          onSuccess: () => {
            this.$emit("nextQuestion", 2);
          },
          onError: () => {
            this.isInvalidAddress = true;
            setTimeout(() => {
              this.isInvalidAddress = false;
            }, 2000);
          },
        });
      }
    },
    goToPreviousPage() {
      this.$emit("previousPage");
    },
    searchOnChange(street) {
      if (street) {
        this.getStreets({ street: street, zip: this.details.zip });
      }
    },
  },
  computed: {
    zipValidation() {
      return this.zipRegex.test(this.details.zip) || this.details.zip === "";
    },
    houseNumberValidation() {
      return (
        this.houseRegex.test(this.details.house_number) ||
        this.details.house_number === ""
      );
    },
    isValidForm() {
      return this.zipValidation && this.houseNumberValidation;
    },
  },

  watch: {
    selectedStreet(newStreet) {
      if (newStreet) {
        this.details.street = newStreet;
      } else {
        this.details.street = "";
      }
    },

    // Add your new watch property here
    allStreets: {
      handler(newStreetValue, oldStreetValue) {
        this.details.street = "";
        this.selectedStreet = "";
        if (newStreetValue.length > 0) {
          this.streetItems = newStreetValue.map((item) => {
            return item.street;
          });
        } else {
          this.streetItems = [];
        }
      },
      immediate: true, // Trigger the watch immediately on component mount
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  // &__right-col {
  //   background-image: url(../../assets/img/firstQuestion.png);
  //   background-size: 80% 80% !important;
  //   background-repeat: no-repeat;
  //   background-position: center;
  // }
}
.form-control {
  // text-transform: uppercase;
}
.street-dropdown {
  color: #bbbabc !important;
}

.auto-complete {
  width: 100% !important;
}

.invalid-text {
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
</style>
