<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <!-- <img
          src="@/assets/img/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        /> -->
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">
          Ben je op de hoogte over de vergoedingen per pakket?
        </p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                id="radio-group-2"
                v-model="selected"
                :aria-describedby="ariaDescribedby"
                name="radio-sub-component"
              >
                <div class="check-item" @click="handleClick('3884')">
                  <input
                    type="radio"
                    id="RadioButton1"
                    name="Button"
                    value="3884"
                  />
                  <label for="RadioButton" class="question-label">Ja</label>
                </div>

                <div class="check-item mt-3" @click="handleClick('3881')">
                  <input
                    type="radio"
                    id="RadioButton2"
                    name="Button"
                    value="3881"
                  />
                  <label for="RadioButton" class="question-label">Nee</label>
                </div>
              </b-form-radio-group>
            </b-form-group>
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection />
        <!-- <div
            class="d-flex w-100 justify-content-center align-items-center question-card__right-col-text mt-5"
          >
            <span>Walter gropiuserf 6, Amersfoort</span>
          </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";

export default {
  name: "FourthQuestion",
  data() {
    return {
      selected: "",
    };
  },
  components: {
    GoogleMapSection,
  },
  props: {
    question: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(val) {
      this.$emit("nextQuestion", "5");
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "3");
    },
  },
  watch: {
    selected() {
      this.$emit("nextQuestion", "5", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-size: 100% 100% !important;
    // background-image: url(../../assets/img/secondQuestion.png);
  }
  #RadioButton1 {
    appearance: none;
    padding: 10px;
    background-color: #00e268;
    border-radius: 50%;
  }
  #RadioButton2 {
    appearance: none;
    padding: 10px;
    background-color: #e3007a;
    border-radius: 50%;
  }
  label {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 23px;
    color: #1d1729;
    padding-left: 11px;
    font-weight: 600;
    cursor: pointer !important;
  }
}
</style>
