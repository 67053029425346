<template>
  <div>
    <b-row
      class="d-flex d-lg-none flex-column justify-content-start align-items-start btn_div mt-3"
    >
      <b-col>
        <button @click="$emit('onCardButtonClick')" class="w-100">
          MELD JE NU AAN
          <span
            ><img src="../assets/img/arrowRight-black.png" class="arrow_img"
          /></span>
        </button>
      </b-col>
    </b-row>
    <b-row class="cards">
      <b-col
        v-for="(card, index) in cards"
        :key="index"
        lg="3"
        xl="3"
        md="6"
        sm="6"
        cols="6"
        class="card__div"
      >
        <div
          class="cards__box d-flex justify-content-center align-items-center flex-column p-2"
        >
          <img :src="card.imgSrc" alt="" class="cards__box-img" />
          <p class="cards__box-text mb-0 mt-1">{{ card.text }}</p>
          <p class="cards__box-text mb-0">{{ card.text1 }}</p>
        </div>
      </b-col>
    </b-row>
    <b-row
      class="d-none d-lg-flex flex-column justify-content-start align-items-start btn_div"
    >
      <b-col>
        <button @click="$emit('onCardButtonClick')">
          MELD JE NU AAN
          <span
            ><img src="../assets/img/arrowRight-black.png" class="arrow_img"
          /></span>
        </button>
      </b-col>
      <b-col class="d-flex justify-content-end col-6">
        <h6 class="btn_text">Klaar binnen 1 minuut</h6>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "Cards",
  methods: {
    handleclick(e) {
      this.$emit("massage ", e);
    },
  },
  data() {
    return {
      cards: [
        {
          imgSrc: require("../assets/img/money.png"),
          text: "Je verdient  €0,25 ",
          text1: "per pakketje",
        },
        {
          imgSrc: require("../assets/img/Smiley.png"),
          text: "Je maakt  ",
          text1: "je buren blij",
        },
        {
          imgSrc: require("../assets/img/profile.png"),
          text: "Je hebt meer ",
          text1: "contact met je wijk",
        },
        {
          imgSrc: require("../assets/img/gift-card.png"),
          text: "Kans op een",
          text1: "zalando cadeaubon",
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.cards {
  .card__div {
    @media (max-width: 992px) {
      margin-bottom: 1rem;
    }
    @media (max-width: 580px) {
      // width: 40%;
      width: 50% !important;
    }
  }
  padding: 2rem 0;
  &__box {
    border-radius: 10px;
    filter: drop-shadow(0px 5px 6.5px rgba(0, 0, 0, 0.35));
    background-color: #efefef;
    cursor: pointer;
    text-align: center;
    height: 8rem;
    @media (max-width: 1024px) {
      height: 10rem;
      &-img {
        width: 30% !important;
      }
    }
    @media (max-width: 580px) {
      height: 12rem;
    }

    @media (max-width: 1399px) {
      &-img {
        width: 40%;
      }
      &-text {
        font-size: 14px;
      }
    }

    &-img {
      // width: 40%;
      // height: 36%;
    }

    &-text {
      font-size: 15px;
      color: #000000;
      font-weight: 800;
      font-family: "Source Sans 3", sans-serif;
      text-align: center;
      @media (max-width: 1024px) {
        font-size: 13px;
      }
    }
  }
}
button {
  border-radius: 37px;
  background-color: #00e268;
  border: 0;
  padding: 12px 5rem;
  font-size: 15px;
  letter-spacing: 2px;
  text-transform: uppercase;
  // color: #ffffff;
  color: black !important;
  font-weight: 900;
  font-family: "Source Sans 3", sans-serif;
  text-align: center;
  @media (max-width: 1024px) {
    padding: 7px 3rem;
  }
}
.arrow_img {
  width: 22px;
  margin-left: 31px;
}
.btn_text {
  font-size: 15px;
  letter-spacing: 0px;
  line-height: 36px;
  color: #ffffff;
  font-weight: 400;
  font-family: "Source Sans 3", sans-serif;
  @media (max-width: 1024px) {
    font-size: 13px;
  }
}
</style>