import axios from "axios";
import authHeader from "./AuthHeader";

const API_URL =
  "https://cors-platform.herokuapp.com/https://leadgen.republish.nl/api";

class LeadService {
  createLead(payload) {
    return axios.post(API_URL + "/sponsors/2257/leads", payload, {
      headers: authHeader(),
    });
  }
}

export default new LeadService();
