<template>
  <b-container class="">
    <b-row class="content_row">
      <b-col lg="6" xl="6" md="10" sm="10" xs="10">
        <Bullets />
      </b-col>
      <b-col
        lg="6"
        xl="6"
        md="10"
        sm="10"
        xs="10"
        class="gallery_col d-flex jusitfy-content-center py-5"
      >
        <Gallery />
      </b-col>
    </b-row>
  </b-container>
  <!-- <div class="container ">
    <div class="row py-5 content_row">
      <div class="col-6 bullet__col">
        <Bullets />
      </div>
      <div class="col-6 gallery_col">
        <Gallery />
      </div>
    </div>
  </div> -->
</template>

<script>
import Bullets from "./Bullets.vue";
import Gallery from "./Gallery.vue";

export default {
  name: "Content",
  components: {
    Bullets,
    Gallery,
  },
};
</script>
<style scoped lang="scss">
.content_row {
  @media (max-width: 992px) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem 0;
  }
}
.gallery_col {
  @media (max-width: 992px) {
    margin-bottom: 8rem;
  }
  @media (max-width: 767.99px) {
    margin-bottom: 0px;
  }
}
/* .content_row {
  height: 66vh;
  justify-content: center;
  align-items: center;
  @media (max-width: 992px) {
    justify-content: center;
    align-items: center;
  }
}
.bullet__col {
  @media (max-width: 992px) {
    width: 100%;
  }
}
.gallery_col {
  @media (max-width: 992px) {
    height: 78vh;
  }
  @media (max-width: 768px) {
    height: 54vh;
  }
  @media (max-width: 510px) {
    height: 50vh;
  }
  @media (max-width: 480px) {
    height: 40vh;
  }
  @media (max-width: 400px) {
    height: 30vh;
  }
} */
</style>
