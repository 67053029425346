<template>
  <div>
    <Header @pageClick="previousPage" />
    <div class="home" v-if="page == 'first'">
      <Hero @nextPage="nextPage" />
      <ContentHome />
      <Footer @nextPage="nextPage" :postdata="postdata" />
    </div>
    <div v-else>
      <QuestionsSection :postdata="postdata" @previousPage="previousPage" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "../components/Hero.vue";
import ContentHome from "../components/ContentHome.vue";
import QuestionsSection from "../components/QuestionsSection.vue";
import Footer from "../components/Footer.vue";

import Header from "../components/Header.vue";
export default {
  name: "Home",
  components: {
    Hero,
    ContentHome,
    QuestionsSection,
    Footer,
    Header,
  },
  data() {
    return {
      page: "first",
      postdata: {
        house_number: "",
        zip: "",
        typePost: false,
      },
    };
  },
  methods: {
    nextPage(type) {
      if (type) {
        this.postdata.typePost = true;
      }
      this.page = "second";
    },
    previousPage() {
      this.page = "first";
    },
  },
  mounted() {
    let useScript = document.createElement("script");

    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };

        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";

        n.queue = [];
        t = b.createElement(e);
        t.async = !0;

        t.src = v;
        s = b.getElementsByTagName(e)[0];

        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),

      fbq("init", "382090309408938"),

      fbq("track", "PageView")
    );
    document.head.appendChild(useScript);
  },

  created() {
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=382090309408938&ev=PageView&noscript=1"/></noscript>';
  },
};
</script>
<style scoped lang="scss">
.home {
  overflow: hidden;
}
</style>
