<template>
  <div class="question-card w-100">
    <b-row no-gutters>
      <b-col cols="8" class="question-card__left-col">
        <!-- <img
          src="@/assets/img/arrowleft.png"
          class="back-arrow"
          alt="arrow"
          @click="goToPreviousPage"
        /> -->
        <p class="question-card__progress mb-0">
          Vraag {{ question }} van {{ max }}
        </p>
        <p class="question-card__question">
          Hoeveel mensen wonen er in jouw huishouden
        </p>

        <div class="row no-gutters">
          <div class="col-lg-10 col-12">
            <b-form-input
              class="w-80"
              type="number"
              min="1"
              required
              placeholder="Vul hier het aantal bewoners in"
            ></b-form-input>
            <b-button class="mt-3 w-100" @click="handleClick('analyze')"
              >Ga verder</b-button
            >
          </div>
        </div>
      </b-col>
      <b-col cols="4" class="p-0 question-card__right-col">
        <GoogleMapSection />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import GoogleMapSection from "../GoogleMapSection.vue";

export default {
  name: "FifthQuestion",
  data() {
    return {
      selected: "",
    };
  },
  components: {
    GoogleMapSection,
  },
  props: {
    question: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(val) {
      this.$emit("nextQuestion", val);
    },
    goToPreviousPage() {
      this.$emit("previousQuestion", "4");
    },
  },
  watch: {
    selected() {
      this.$emit("nextQuestion", "5", this.selected);
    },
  },
};
</script>

<style lang="scss" scoped>
.question-card {
  &__right-col {
    background-size: 100% 100% !important;
    // background-image: url(../../assets/img/secondQuestion.png);
  }
  #RadioButton1 {
    appearance: none;
    padding: 10px;
    background-color: #00e268;
    border-radius: 50%;
  }
  #RadioButton2 {
    appearance: none;
    padding: 10px;
    background-color: #e3007a;
    border-radius: 50%;
  }
  label {
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 23px;
    color: #1d1729;
    padding-left: 11px;
    font-weight: 600;
    cursor: pointer !important;
  }
}
</style>
